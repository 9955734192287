.watch-dropdown3 {
  background: transparent !important;
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  border: 1px solid #000000 !important;
  border-radius: 50px !important;

  padding: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 172.7% !important;
}

.watch-dropdown3::after {
  display: none !important;
}
